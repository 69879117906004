import 'swiper/css';
import 'swiper/css/effect-cards';

import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { faChevronLeft, faPlus, faRecordVinyl } from '@fortawesome/pro-regular-svg-icons';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PromptStatus, PromptType } from '@remento/types/project';
import { EffectCards, Virtual } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import { Swiper as SwiperInstance } from 'swiper/types';

import customPromptIconSrc from '@/assets/custom-prompt-icon.svg';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { getQueryParam } from '@/hooks/useQueryParam';
import { RecordCard } from '@/modules/project-record/components/ProjectRecordCard';
import { RecordingModeDialog } from '@/modules/project-record/components/RecordingModeDialog';
import { PromptRecordCardContainer } from '@/modules/project-record/containers/PromptRecordCard.container';
import { useAvailablePromptTemplates } from '@/modules/prompt/hooks/available-prompt.hook';
import {
  getProjectRecordCustomizePath,
  getRecordingFinishPath,
  getRecordingPath,
  getRecordingPromptRecordedPath,
  RementoPage,
} from '@/modules/routing';
import { useServices } from '@/Services';
import { useProjectQuery, useSortedPromptIds, useUnrecordedProjectPrompts } from '@/services/api/project';
import { PromptTemplate } from '@/services/cms/prompt-template/prompt-template.types';
import { captureException } from '@/utils/captureException';
import { secureUuid } from '@/utils/uuid';

import {
  CustomPromptCardContent,
  CustomPromptCardIcon,
  Header,
  Pagination,
  PaginationButton,
  StyledProjectRecordPage,
  StyledSwiper,
} from './ProjectRecordPage.styles';

interface InternalProjectRecordPageProps {
  projectId: string;
}

interface DefaultPageState {
  type: 'default';
}

interface ChoosePromptRecordingTypePageState {
  type: 'choose-recording-type';
  cardType: 'prompt';
  promptId: string;
}

interface ChooseTemplateRecordingTypePageState {
  type: 'choose-recording-type';
  cardType: 'template';
  template: PromptTemplate;
}

type PageState = DefaultPageState | ChoosePromptRecordingTypePageState | ChooseTemplateRecordingTypePageState;

interface PromptSlide {
  type: 'prompt';
  key: string;
  promptId: string;
}

interface PromptTemplateSlide {
  type: 'prompt-template';
  key: string;
  template: PromptTemplate;
}

interface CustomPromptSlide {
  type: 'custom-prompt';
  key: string;
}

type Slide = PromptSlide | PromptTemplateSlide | CustomPromptSlide;

// TODO - Set the appropriate tag to the get correct templates.
const TRENDING_PROMPT_TEMPLATES_TAG = '';

function InternalProjectRecordPage({ projectId }: InternalProjectRecordPageProps) {
  // Services
  const { projectCacheService } = useServices();
  const navigate = useNavigate();

  // Queries
  const projectQuery = useProjectQuery(projectId);
  const unsortedSentPromptsIds = useUnrecordedProjectPrompts(projectId, PromptStatus.SENT);
  const sentPromptsIds = useSortedPromptIds(unsortedSentPromptsIds, 'createdAt', 'desc');
  const availablePromptTemplates = useAvailablePromptTemplates(projectId, {
    templateTag: TRENDING_PROMPT_TEMPLATES_TAG,
  });

  // Swiper state
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = useMemo<Slide[]>(() => {
    if (projectQuery.data == null || availablePromptTemplates == null) {
      return [];
    }
    const promptSlides: PromptSlide[] = sentPromptsIds.map((promptId) => ({
      type: 'prompt',
      key: `prompt-${promptId}`,
      promptId,
    }));
    const customPromptSlide: CustomPromptSlide = {
      type: 'custom-prompt',
      key: 'custom-prompt',
    };

    if (projectQuery.data.configuration.type === 'BABYBOOK') {
      return [...promptSlides, customPromptSlide];
    }

    const promptTemplateSlides: PromptTemplateSlide[] = availablePromptTemplates.slice(0, 3).map((template) => ({
      type: 'prompt-template',
      key: `template-${template.id}`,
      template,
    }));

    return [...promptSlides, ...promptTemplateSlides, customPromptSlide];
  }, [projectQuery.data, sentPromptsIds, availablePromptTemplates]);

  // Page state
  const isMobile = useIsMobileViewport();
  const [pageState, setPageState] = useState<PageState>({ type: 'default' });

  const [searchParams] = useSearchParams();
  const handleRecordPrompt = useCallback(
    async (type: 'audio' | 'video') => {
      if (pageState.type != 'choose-recording-type') {
        return;
      }

      if (pageState.cardType == 'prompt') {
        navigate(
          getRecordingPath({
            projectId,
            promptId: pageState.promptId,
            type,
            catchup: true,
            customParams: searchParams,
          }),
        );
        return;
      }

      try {
        const promptId = secureUuid();
        await projectCacheService.createPrompts(projectId, [
          {
            id: promptId,
            status: PromptStatus.SENT,
            type: PromptType.TEXT,
            question: pageState.template.questions[0].text,
            template: {
              id: pageState.template.id,
              tagIds: [TRENDING_PROMPT_TEMPLATES_TAG],
            },
          },
        ]);
        navigate(getRecordingPath({ projectId, promptId, type, catchup: true, customParams: searchParams }));
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    },
    [pageState, projectCacheService, projectId, navigate, searchParams],
  );

  const handleCreateCustomPrompt = useCallback(() => {
    navigate(getProjectRecordCustomizePath(projectId, searchParams));
  }, [navigate, projectId, searchParams]);

  const handleBack = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('referrer');

    let route: string;
    switch (getQueryParam('referrer')) {
      case 'prompt-already-recorded': {
        const referrerPromptId = getQueryParam('referrer-prompt-id');
        if (referrerPromptId != null) {
          route = getRecordingPromptRecordedPath(projectId, referrerPromptId);
        } else {
          route = getRecordingFinishPath(projectId, newSearchParams);
        }
        break;
      }
      case 'recording-finish': {
        route = getRecordingFinishPath(projectId, newSearchParams);
        break;
      }
      default: {
        route = getRecordingFinishPath(projectId, newSearchParams);
        break;
      }
    }
    navigate(route);
  }, [navigate, projectId, searchParams]);

  return (
    <StyledProjectRecordPage>
      <Header>
        <RMIconButton
          icon={faChevronLeft}
          size="xl"
          tooltip={{ label: 'Go back', position: 'bottom' }}
          backgroundColor="transparent"
          onClick={handleBack}
        />
        <RMText type="sans" size="m" color="on-surface-primary" bold>
          My prompts
        </RMText>
        <RMIconButton
          icon={faPlus}
          size="xl"
          tooltip={{ label: 'Add a new prompt', position: 'bottom' }}
          backgroundColor="transparent"
          onClick={handleCreateCustomPrompt}
        />
      </Header>

      <StyledSwiper
        effect="cards"
        grabCursor
        modules={[EffectCards, Virtual]}
        cardsEffect={{ slideShadows: false }}
        virtual={{ addSlidesAfter: 5, addSlidesBefore: 5 }}
        onSwiper={setSwiper}
        onSlideChange={(s) => setCurrentSlide(s.activeIndex)}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={slide.key} virtualIndex={index}>
            {/* Prompt */}
            {slide.type === 'prompt' && (
              <PromptRecordCardContainer
                promptId={slide.promptId}
                onRecord={() =>
                  setPageState({ type: 'choose-recording-type', cardType: 'prompt', promptId: slide.promptId })
                }
              />
            )}
            {/* Prompt Template */}
            {slide.type === 'prompt-template' && (
              <RecordCard
                header={
                  <>
                    <FontAwesomeIcon icon={faBolt} />
                    Trending on Remento
                  </>
                }
                content={slide.template.questions[0].text}
                actionLabel="Record my answer"
                actionIcon={faRecordVinyl}
                onAction={() =>
                  setPageState({
                    type: 'choose-recording-type',
                    cardType: 'template',
                    template: slide.template,
                  })
                }
              />
            )}
            {/* Custom Prompt */}
            {slide.type === 'custom-prompt' && (
              <RecordCard
                content={
                  <CustomPromptCardContent>
                    <CustomPromptCardIcon src={customPromptIconSrc} />
                    <RMText type="sans" size={isMobile ? 's' : 'm'} bold color="on-surface-primary">
                      Feeling inspired?
                    </RMText>
                  </CustomPromptCardContent>
                }
                actionLabel="Add my own prompt"
                actionIcon={faPlus}
                onAction={handleCreateCustomPrompt}
              />
            )}
          </SwiperSlide>
        ))}
      </StyledSwiper>

      <RMSpacer spacing="2xl" direction="column" />

      <Pagination>
        <PaginationButton disabled={currentSlide === 0} onClick={() => swiper?.slidePrev(500)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </PaginationButton>
        <RMText type="sans" size="xs" color="on-surface-primary" bold>
          {currentSlide + 1} of {slides.length}
        </RMText>
        <PaginationButton disabled={currentSlide + 1 === slides.length} onClick={() => swiper?.slideNext(500)}>
          <FontAwesomeIcon icon={faArrowRight} />
        </PaginationButton>
      </Pagination>

      <RecordingModeDialog
        open={pageState.type === 'choose-recording-type'}
        onChooseMode={handleRecordPrompt}
        onClose={() => setPageState({ type: 'default' })}
      />
    </StyledProjectRecordPage>
  );
}

export function ProjectRecordPage() {
  const params = useParams();

  return (
    <RementoPage type="default">
      <InternalProjectRecordPage projectId={params.projectId ?? ''} />
    </RementoPage>
  );
}
